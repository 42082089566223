<template>
  <div class="login-screen">
    <FormSignUp :planName="planName" />
  </div>
</template>

<script>
// @ is an alias to /src
import FormSignUp from "@/components/form-signup.vue";

export default {
  name: "SignUp",
  props: {
    planName: {
      type: String,
      default: "",
      required: false
    }
  },
  components: {
    FormSignUp
  },
  mounted: function() {
    this.$store.dispatch("reset");
  }
};
</script>

<style scoped>
.login-screen {
  background: #d2d6de;
  background: rgb(209, 227, 226);
  background: linear-gradient(
    180deg,
    rgba(209, 227, 226, 1) 0%,
    rgba(154, 171, 175, 1) 67%
  );
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 0;
  box-shadow: 0px 0px 6px grey;
}
.login-box {
  margin: 20px auto;
}

@media (min-width: 1720px) {
  .login-screen {
    padding-bottom: 2060px;
  }
}
@media (max-width: 1720px) {
  .login-screen {
    padding-bottom: 780px;
  }
}
@media (max-width: 1200px) {
  .login-screen {
    padding-bottom: 900px;
  }
}
@media (max-width: 992px) {
  .login-screen {
    padding-bottom: 800px;
  }
}
@media (max-width: 768px) {
  .login-screen {
    padding-bottom: 900px;
  }
}
</style>
